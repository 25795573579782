
function toggleAddOnButton(btn) {
	let added = btn.dataset.added
	if(added === "false") {
		btn.classList.remove('black');
		btn.classList.add('red-wh');
		btn.innerHTML = "Remove";
		btn.dataset.added = "true";
	} else {
		btn.classList.remove('red-wh');
		btn.classList.add('black');
		btn.innerHTML = "Add";
		btn.dataset.added = "false";
	}
}
